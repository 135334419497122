import cn from "classnames";

import type { TeaserFooter as Footer } from "../../../types/content/teaser";
import type { ArticleStyle } from "../../../types/content";

import styles from "./TeaserFooter.module.scss";
import { Timestamp } from "../../Timestamp/Timestamp";

interface TeaserFooterProps {
  style: ArticleStyle;
  type?: Footer;
  timestamp: string;
}

const TeaserFooterStamp = () => {
  const badges: { [key: string]: string | undefined } = {
    core: "Omni Mer",
    ekonomi: undefined,
  };

  const badgeText = badges[process.env.NEXT_PUBLIC_UI_THEME || ""];

  if (!badgeText) {
    return null;
  }

  return <span>{badgeText}</span>;
};

const TeaserFooter = ({ style, type, timestamp }: TeaserFooterProps) => {
  if (!type) {
    return null;
  }

  if (type.type === "timestamp") {
    return (
      <div className={cn([styles.teaserFooter, styles.regular, styles[style]])}>
        <Timestamp showRelativeTime={true} dateTime={timestamp} />
      </div>
    );
  }

  if (type.type === "badge") {
    return (
      <div className={cn([styles.teaserFooter, styles[style]])}>
        <TeaserFooterStamp />
      </div>
    );
  }

  return null;
};

export { TeaserFooter };
export type { TeaserFooterProps };
