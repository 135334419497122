import type {
  ArticleStyle,
  Url,
  Image,
  Map as MapType,
  ExternalImage,
} from "../../../types/content";

import styles from "./TeaserLargeMedia.module.scss";
import { Map } from "../../Map/Map";
import { Video } from "../../Video/Video";
import { TeaserImage } from "../TeaserImage/TeaserImage";

interface TeaserLargeMediaProps {
  image?: Image | Url | MapType | ExternalImage;
  imageUrl?: string;
  style: ArticleStyle;
  hasPriorityImage?: boolean;
}

const TeaserLargeMedia = ({
  image,
  imageUrl,
  style,
  hasPriorityImage,
}: TeaserLargeMediaProps) => {
  const hasImage = image?.type === "Image";
  const hasVideoContent =
    image?.type === "Url" && image?.data?.type === "video";
  const hasMapContent = image?.type === "Map";
  const hasExternalImage = image?.type === "ExternalImage";

  if (hasImage || hasExternalImage) {
    const url = hasExternalImage ? (image as ExternalImage)?.url : imageUrl;
    const alt = hasImage ? image?.caption?.value : "";
    return (
      <TeaserImage
        size="large"
        imageUrl={url}
        style={style}
        alt={alt}
        hasPriorityImage={hasPriorityImage}
      />
    );
  }

  if (hasVideoContent) {
    return (
      <Video
        resource={image}
        isMainResource
        thumbnailContainerClassName={styles.videoThumbnail}
        hasPadding={false}
      />
    );
  }

  if (hasMapContent) {
    return (
      <Map resource={image} hasGoogleLink={false} className={styles.map} />
    );
  }

  return null;
};

export { TeaserLargeMedia };
