import type { Map } from "../../../types/content";

const uiTheme = process.env.NEXT_PUBLIC_UI_THEME;

interface MapDimensions {
  width: number;
  height: number;
}

export function getStaticMapSrc(
  { width, height }: MapDimensions,
  { location, zoom }: Map,
) {
  const markerColor = uiTheme === "ekonomi" ? "tomato" : "teal";

  return `https://maps.vgc.no/api/maps/basic/static/${location.lon},${location.lat},${zoom}/${width}x${height}.png?markers=${location.lon},${location.lat},${markerColor}`;
}
