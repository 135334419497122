"use client";

import { useEffect, useRef } from "react";
import { usePathname, useSearchParams } from "next/navigation";

import type { PageType } from "@schibsted/pulse-news-media";
import type { Article } from "../../types/content";
import type { ReadingContext } from "../../types/pulse";

import { usePulse } from "../../pulse/usePulse";
import { updateOrigin, updateObjectPage } from "../../pulse";
import {
  isArticlePage,
  isCategoryPage,
  isFrontPage,
  isNewsMixPage,
  isSearchPage,
  isTopicPage,
} from "../../utils/page";
import { trackViewListPage } from "../../pulse/event/trackListingPage";
import { getTitleFromSlug } from "../../utils/getTitleFromSlug";

interface PulseViewEventsProps {
  type: PageType;
  id?: string;
  article?: Article;
  readingContext?: ReadingContext;
  isPremiumUser?: boolean;
}

const isOmniEconomy = process.env.NEXT_PUBLIC_UI_THEME === "ekonomi";

const PulseViewEvent = ({
  type,
  id,
  article,
  readingContext,
  isPremiumUser,
}: PulseViewEventsProps) => {
  const {
    trackViewArticle,
    trackViewFrontpage,
    trackPageLeave,
    trackViewNewsMixPage,
  } = usePulse();
  const searchParams = useSearchParams();
  const abortController = useRef<AbortController>();
  const pathname = usePathname();
  const runOnce = useRef(true);

  useEffect(() => {
    const currentUrl = window.location.href;

    const verifyReadingContext = () => {
      return readingContext === "modal" || readingContext === "page";
    };

    const onVisibilityChange = () => {
      if (document.hidden) {
        onBeforeUnload();
      }
    };

    const onBeforeUnload = () => {
      if (runOnce.current) {
        trackPageLeave();
        runOnce.current = false;
        if (abortController.current) {
          abortController.current.abort();
        }
      }
    };

    if (
      type === "Frontpage" &&
      id &&
      isFrontPage(pathname) &&
      runOnce.current
    ) {
      updateObjectPage({
        id: id,
        type,
        url: currentUrl,
        name: id,
      });

      trackViewFrontpage(id);

      updateOrigin({
        id: id,
        type,
        url: currentUrl,
        name: id,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });

      runOnce.current = false;
    } else if (
      type === "Article" &&
      article &&
      readingContext &&
      verifyReadingContext() &&
      isArticlePage(pathname) &&
      article.article_id === pathname.split("/").at(-1) &&
      runOnce.current
    ) {
      updateObjectPage({
        id: article.article_id,
        type,
        url: currentUrl,
        name: article.title.value,
      });

      // in case article is premium and user is not, we use PulseTrackSalesPosterImpression
      const shouldTrackArticleView =
        (!isOmniEconomy && !article.is_premium) ||
        (isOmniEconomy && isPremiumUser) ||
        (article.is_premium && isPremiumUser);

      if (shouldTrackArticleView) {
        trackViewArticle(article, readingContext, currentUrl);

        updateOrigin({
          id: article.article_id,
          type,
          url: currentUrl,
          name: article.title.value,
          source: searchParams.get("utm_source") || undefined,
          channel:
            searchParams.get("utm_medium") ||
            searchParams.get("utm_channel") ||
            undefined,
          content: searchParams.get("utm_content") || undefined,
          campaign: searchParams.get("utm_campaign") || undefined,
          terms: searchParams.get("utm_terms") || undefined,
        });
      }

      abortController.current = new AbortController();

      document.addEventListener("visibilitychange", onVisibilityChange, {
        signal: abortController.current.signal,
      });
      window.addEventListener("beforeunload", onBeforeUnload, {
        signal: abortController.current.signal,
      });

      runOnce.current = false;
    } else if (type === "Page" && isNewsMixPage(pathname) && runOnce.current) {
      const newsMixPageId = "sdrn:omnise:page:newsMixSettingsPage";
      const newsMixPageName = "Newsmix Settings Page";

      updateObjectPage({
        id: newsMixPageId,
        type,
        url: currentUrl,
        name: newsMixPageName,
      });

      trackViewNewsMixPage(newsMixPageId, newsMixPageName);

      updateOrigin({
        type,
        url: currentUrl,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });
      runOnce.current = false;
    } else if (
      type === "Listing" &&
      isCategoryPage(pathname, id) &&
      runOnce.current
    ) {
      const categoryName = currentUrl.split("/").pop();
      updateObjectPage({
        id,
        type,
        url: currentUrl,
        name: categoryName,
      });

      trackViewListPage(id!, categoryName!);

      updateOrigin({
        id: id,
        type,
        url: currentUrl,
        name: categoryName,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });

      runOnce.current = false;
    } else if (
      type === "Listing" &&
      isTopicPage(pathname, id) &&
      runOnce.current
    ) {
      const splittedUrl = currentUrl.split("/");
      const lengthOfSplittedUrl = splittedUrl.length;
      const topicNameSlug = splittedUrl.slice(
        lengthOfSplittedUrl - 2,
        lengthOfSplittedUrl - 1,
      )[0];

      const topicName = getTitleFromSlug(topicNameSlug);

      updateObjectPage({
        id,
        type,
        url: currentUrl,
        name: topicName,
      });

      trackViewListPage(id!, topicName!);

      updateOrigin({
        id: id,
        type,
        url: currentUrl,
        name: topicName,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });

      runOnce.current = false;
    } else if (type === "Listing" && isSearchPage(id) && runOnce.current) {
      const name = "Search";
      updateObjectPage({
        id,
        type,
        url: currentUrl,
        name,
      });

      trackViewListPage(id!, name);

      updateOrigin({
        id: id,
        type,
        url: currentUrl,
        name,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });

      runOnce.current = false;
    }

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [
    trackViewFrontpage,
    trackViewArticle,
    trackPageLeave,
    article,
    readingContext,
    type,
    id,
    searchParams,
    pathname,
    trackViewNewsMixPage,
    isPremiumUser,
  ]);

  return null;
};

export { PulseViewEvent };
