import { getNewsMediaPulseTracker } from "..";

const trackViewListPage = (id: string, name: string) => {
  const newsMediaPulseTracker = getNewsMediaPulseTracker();

  newsMediaPulseTracker?.executeNewsMediaPulseCommand("trackViewListing", {
    object: {
      id,
      name,
    },
  });
};
export { trackViewListPage };
