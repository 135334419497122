import type { Image } from "../types/content";

const imageServer =
  process.env.NEXT_PUBLIC_IMAGE_SERVER || "https://gfx.omni.se";
const watermarkServer = "https://watermark-gfx.omni.se";

type Size =
  | "large"
  | "small"
  | "expanded"
  | "sidebar"
  | "1x1"
  | "1x2"
  | "share";

type IdFields = Pick<Image, "image_asset" | "source_id" | "watermarks">;

type ImageProperties = {
  height: number;
  width: number;
  tight?: boolean;
};

type SizeMapping = { [key in Size]: ImageProperties };

const sizeNameToSizeValues: SizeMapping = {
  large: { width: 1372, height: 708, tight: false },
  expanded: { width: 1200, height: 600 },
  small: { width: 180, height: 180, tight: true },
  sidebar: { width: 80, height: 80, tight: true },
  "1x1": { width: 1372, height: 1372, tight: true },
  "1x2": { width: 640, height: 320, tight: true },
  share: { width: 1200, height: 628, tight: false },
};

const imageSrc = (
  size: Size,
  useFallback: boolean,
  image?: IdFields,
): string => {
  if (!image) {
    return "";
  }

  const {
    width,
    height,
    tight: maybeTight = null,
  } = sizeNameToSizeValues[size];
  const tightQuery = maybeTight !== null ? `&tight=${maybeTight}` : "";
  const id = useFallback ? image.image_asset.id : image.source_id;

  if (image.watermarks) {
    const watermark = image.watermarks.filter((w) => w.format === "2x1")[0].url;
    return `${watermarkServer}/images/${id}?h=${height}${tightQuery}&w=${width}&watermark=${watermark}`;
  } else {
    return `${imageServer}/images/${id}?h=${height}${tightQuery}&w=${width}`;
  }
};

export { imageSrc, sizeNameToSizeValues };
