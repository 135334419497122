import Image from "next/image";
import cn from "classnames";
import React from "react";

import type { ArticleStyle } from "../../../types/content";

import styles from "./TeaserImage.module.scss";
import { sizeNameToSizeValues } from "../../../utils/image";

interface TeaserImageProps {
  imageUrl?: string;
  size: "small" | "large";
  style: ArticleStyle;
  alt?: string;
  hasPriorityImage?: boolean;
  isVideo?: boolean;
}

const TeaserImage = ({
  imageUrl,
  size,
  style,
  alt,
  hasPriorityImage,
  isVideo = false,
}: TeaserImageProps) => {
  if (!imageUrl) {
    return null;
  }

  const imgProps = sizeNameToSizeValues[size];

  return (
    <div
      className={cn([
        styles.teaserImage,
        styles[size],
        styles[style],
        isVideo && styles.videoContainer,
      ])}
    >
      <Image
        src={imageUrl}
        width={imgProps.width}
        height={imgProps.height}
        alt={alt || ""}
        priority={hasPriorityImage}
      />
      {isVideo && (
        <Image
          className={styles.videoIcon}
          data-testid="videoPlayButton"
          alt=""
          height={10}
          width={10}
          src={`${process.env.NEXT_PUBLIC_ASSET_PREFIX || ""}/images/playbtn.png`}
        />
      )}
    </div>
  );
};

export { TeaserImage };
