const FromDateTime = ({
  dateTime,
  showRelativeTime,
}: {
  dateTime: string;
  showRelativeTime?: boolean;
}) => {
  const date = new Date(dateTime);
  const now = new Date(Date.now());
  const diff = now.getTime() - date.getTime();
  const diffDate = new Date(diff);

  const months = [
    "januari",
    "februari",
    "mars",
    "april",
    "maj",
    "juni",
    "juli",
    "augusti",
    "september",
    "oktober",
    "november",
    "december",
  ];
  const oneMinute = 60 * 1000;
  const oneHour = 3600 * 1000;
  const fiveHours = 5 * 3600 * 1000;
  const oneDay = 24 * 3600 * 1000;

  const yearIfNotThisYear = (date: Date) => {
    return (
      (date.getFullYear() !== new Date(Date.now()).getFullYear() &&
        date.getFullYear()) ||
      ""
    );
  };

  const getTimeString = (date: Date) => {
    return date.toLocaleTimeString("sv-SV", {
      timeZone: "Europe/Stockholm",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const dmt = (isoTimeString: string) => {
    const date = new Date(isoTimeString);
    const year = yearIfNotThisYear(date) ? ` ${date.getFullYear()}` : "";
    const time = getTimeString(date);

    return `${date.getDate()} ${months[date.getMonth()]}${year}, ${time}`;
  };

  if (showRelativeTime) {
    if (diff < oneMinute) {
      return "nu";
    }

    if (diff < oneHour) {
      return `${diffDate.getUTCMinutes()} min`;
    }

    if (diff < fiveHours) {
      return `${diffDate.getUTCHours()} tim`;
    }

    if (diff < oneDay && now.getDate() === date.getDate()) {
      return `Idag ${getTimeString(date)}`;
    }

    if (diff < 2 * oneDay && now.getDate() === date.getDate() + 1) {
      return `Igår ${getTimeString(date)}`;
    }
  }

  return dmt(dateTime);
};

export { FromDateTime };
