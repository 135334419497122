import React from "react";
import Image from "next/image";
import cn from "classnames";

import type { Value as ClassNameValue } from "classnames";
import type { Map as MapType } from "../../types/content";

import { getImageSizes } from "../ArticleContent/resources/Url/utils";
import { getStaticMapSrc } from "./utils/getStaticMapSrc";
import { getGoogleMapsLink } from "./utils/getGoogleMapsLink";
import styles from "./Map.module.scss";

const sizes = getImageSizes({ web: "668px", tablet: "100vw" });

const Map = ({
  resource,
  hasGoogleLink = true,
  className,
}: {
  resource: MapType;
  hasGoogleLink?: boolean;
  className?: ClassNameValue;
}) => {
  const mapSrc = getStaticMapSrc(
    {
      width: 640,
      height: Math.round(640 / 2),
    },
    resource,
  );

  const googleMapsLink = getGoogleMapsLink(resource);
  return (
    <div className={cn([className ? className : styles.map, styles.mapImg])}>
      {hasGoogleLink ? (
        <a href={googleMapsLink} rel="noopener noreferrer" target="_blank">
          <Image src={mapSrc} alt="karta" sizes={sizes} width={0} height={0} />
        </a>
      ) : (
        <Image src={mapSrc} alt="karta" sizes={sizes} width={0} height={0} />
      )}
    </div>
  );
};

export { Map };
