import React from "react";
import cn from "classnames";

import styles from "./Caption.module.scss";

interface Props {
  text?: string;
  show?: boolean;
  classNameOverride?: string;
}

const Caption = ({ text, show = false, classNameOverride }: Props) => {
  if (!text || !show) return null;
  return (
    <div
      className={cn([!classNameOverride ? styles.caption : classNameOverride])}
    >
      {text}
    </div>
  );
};

export { Caption };
