import cn from "classnames";

import type { TeaserText as TeaserTextT } from "../../../types/content/teaser";
import type { ArticleStyle } from "../../../types/content";

import styles from "./TeaserText.module.scss";

interface TeaserTextProps {
  text?: TeaserTextT;
  style: ArticleStyle;
}

const TeaserText = ({ text, style }: TeaserTextProps) => {
  if (!text) {
    return null;
  }

  return <p className={cn([styles.teaserText, styles[style]])}>{text.value}</p>;
};

export { TeaserText };
export type { TeaserTextProps };
