import cn from "classnames";
import { Heading, HeadingLevel } from "@ariakit/react";

import type { ArticleStyle } from "../../../types/content";
import type { TeaserLayout, TeaserTitle } from "../../../types/content/teaser";

import styles from "./TeaserHeading.module.scss";

interface TeaserHeadingProps {
  title: TeaserTitle;
  style: ArticleStyle;
  layout: TeaserLayout;
}

const TeaserHeading = ({ title, style, layout }: TeaserHeadingProps) => {
  const vignette = title?.vignette?.value;
  return (
    <HeadingLevel>
      <Heading className={cn([styles.teaserTitle, styles[layout]])}>
        {vignette && (
          <span className={cn([styles.titleVignette, styles[style]])}>
            {vignette} •
          </span>
        )}
        {title.value}
      </Heading>
    </HeadingLevel>
  );
};

export { TeaserHeading };
export type { TeaserHeadingProps };
